"use client";

import { Suspense } from "react";
import { Bars3Icon, HomeIcon } from "@heroicons/react/24/outline";
import { Outlet } from "react-router-dom";
import { User } from "firebase/auth";
import { Organization } from "../httpResponseType";

const navigation = [
  { name: "ダッシュボード", href: "#", icon: HomeIcon, current: true },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function AuthLayout({
  currentUser,
  currentOrganization,
}: {
  currentUser: User;
  currentOrganization: Organization;
}) {
  return (
    <>
      <div>
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-blue-600 px-6">
            <div className="flex h-16 shrink-0 items-center">TORUTE</div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-blue-400 text-white"
                              : "text-blue-200 hover:bg-blue-400 hover:text-white",
                            "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                          )}
                        >
                          <item.icon
                            aria-hidden="true"
                            className={classNames(
                              item.current
                                ? "text-white"
                                : "text-blue-200 group-hover:text-white",
                              "h-6 w-6 shrink-0"
                            )}
                          />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <div className="text-xs font-semibold leading-6 text-blue-200">
                    所属組織
                  </div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    <li key={currentOrganization.id}>
                      <a
                        href="#"
                        className="bg-blue-400 text-white group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                      >
                        <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-blue-400 bg-blue-600 text-[0.625rem] font-medium text-white">
                          {currentOrganization.name.slice(0, 2)}
                        </span>
                        <span className="truncate">
                          {currentOrganization.name}
                        </span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="-mx-6 mt-auto">
                  <a
                    href="#"
                    className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-blue-400"
                  >
                    <span className="sr-only">Your profile</span>
                    <span aria-hidden="true">{currentUser.email}</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-blue-600 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-blue-200 lg:hidden"
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
          <a href="#">
            <span className="sr-only">Your profile</span>
            <img
              alt=""
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              className="h-8 w-8 rounded-full bg-blue-400"
            />
          </a>
        </div>

        <main className="py-10 lg:pl-72">
          <div className="px-4 sm:px-6 lg:px-8">
            <Suspense fallback={<div>Loading...</div>}>
              <Outlet />
            </Suspense>
          </div>
        </main>
      </div>
    </>
  );
}
